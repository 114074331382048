<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <b-row>
          <b-col md="6" lg="3">
            <iq-card
              class-name="iq-card-block iq-card-stretch iq-card-height"
              body-class="iq-bg-primary rounded"
            >
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary">
                    <i class="ri-user-fill"></i>
                  </div>
                  <div class="text-right">
                    <h2 class="mb-0">
                      <span class="counter">{{ dashboardData.doctors ?? 0 }}</span>
                    </h2>
                    <h5>Personal de Salud</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card
              class-name="iq-card-block iq-card-stretch iq-card-height"
              body-class="iq-bg-danger rounded"
            >
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-danger">
                    <i class="ri-group-fill"></i>
                  </div>
                  <div class="text-right">
                    <h2 class="mb-0">
                      <span class="counter">{{ dashboardData.patients ?? 0 }}</span>
                    </h2>
                    <h5>Pacientes</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card
              class-name="iq-card-block iq-card-stretch iq-card-height"
              body-class="iq-bg-warning rounded"
            >
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-warning">
                    <i class="ri-women-fill"></i>
                  </div>
                  <div class="text-right">
                    <h2 class="mb-0">
                      <span class="counter">{{ dashboardData.appointments ?? 0 }}</span>
                    </h2>
                    <h5>Citas</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card
              class-name="iq-card-block iq-card-stretch iq-card-height"
              body-class="iq-bg-info rounded"
            >
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-info">
                    <i class="ri-hospital-line"></i>
                  </div>
                  <div class="text-right">
                    <h2 class="mb-0">
                      <span class="counter">{{
                        dashboardData.currentAppointments ?? 0
                      }}</span>
                    </h2>
                    <h5>Citas Actuales</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" v-if="dashboardData.doctors2">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">Personal de Salud</h4>
          </template>
          <template
            v-slot:body
            class="d-flex flex-wrap align-items-center p-0 slick-initialized slick-slider"
          >
            <Slick :option="slickOptions" id="doster-list-slide">
              <div
                class="docter-list-item-inner rounded text-center"
                v-for="doctor in dashboardData.doctors2"
                :key="doctor.id"
              >
                <div class="donter-profile">
                  <img
                    :src="defaultDoctorImage"
                    class="img-fluid rounded-circle"
                    width="100"
                    alt="user-img"
                  />
                </div>
                <div class="doctor-detail mt-3">
                  <h5>{{ doctor.name }}</h5>
                  <h6>{{ doctor.document_number }}</h6>
                </div>
                <hr />
                <div class="doctor-description">
                  <p class="mb-0 text-center pl-2 pr-2">{{ doctor.civil_status }}</p>
                </div>
              </div>
            </Slick>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import IqCard from '../../../components/xray/cards/iq-card'
import api from '../../../services/api'
import { Toast } from '../../../mixins/toast'
import constants from '../../../config/constant'

export default {
  name: 'DashboardAdmin',
  components: { IqCard },
  mounted () {
    xray.index()
    this.getData()
  },
  data () {
    return {
      dashboardData: [],
      slickOptions: {
        centerMode: false,
        centerPadding: '60px',
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '30',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '15',
              slidesToShow: 1
            }
          }
        ],
        nextArrow: '<a href="#" class="ri-arrow-left-s-line left"></a>',
        prevArrow: '<a href="#" class="ri-arrow-right-s-line right"></a>'
      },
      doctorList: [
        {
          name: 'Dr.Paul Molive',
          img: require('../../../assets/images/user/05.jpg'),
          position: 'doctor',
          worksAt: 'California Hospital Medical Center'
        },
        {
          name: 'Dr.Paul Molive',
          img: require('../../../assets/images/user/06.jpg'),
          position: 'Surgeon',
          worksAt: 'California Hospital Medical Center'
        },
        {
          name: 'Dr.Paul Molive',
          img: require('../../../assets/images/user/07.jpg'),
          position: 'OT Assistant',
          worksAt: 'California Hospital Medical Center'
        },
        {
          name: 'Dr.Paul Molive',
          img: require('../../../assets/images/user/08.jpg'),
          position: 'doctor',
          worksAt: 'California Hospital Medical Center'
        },
        {
          name: 'Dr.Paul Molive',
          img: require('../../../assets/images/user/09.jpg'),
          position: 'Nurse',
          worksAt: 'California Hospital Medical Center'
        },
        {
          name: 'Dr.Paul Molive',
          img: require('../../../assets/images/user/10.jpg'),
          position: 'Surgeon',
          worksAt: 'California Hospital Medical Center'
        }
      ],
      defaultDoctorImage: constants.defaultImage
    }
  },
  methods: {
    async getData () {
      try {
        const { data } = await api.get('/dashboard')
        this.dashboardData = data.data
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: error.message
        })
      }
    }
  }
}
</script>

<style>
.iq-card-body {
  flex: unset;
}
</style>
